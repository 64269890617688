import React from 'react';

export const Header = () => {
  return (
    <header className="flex justify-around">
      <div className="brand">
        WoodlandsTech
      </div>
    </header>
  )
}